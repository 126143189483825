import styled from 'styled-components';

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: #034181;
  /* border-bottom: 3px solid #2C7BBF; */
  padding: 0;
  box-sizing: border-box;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const MenuToggle = styled.div`
  cursor: pointer;
  margin-left: 1rem;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fafafa;
  padding: 1rem;
  border: 1px solid #ccc;
`;

const MenuItem = styled.li`
  list-style: none;
  margin: 0.5rem 0;
  cursor: pointer;
`;

const ContainerBadge = styled.div`
  position: relative;
`;

export { Container, Logo, Menu, MenuToggle, MenuItem, ContainerBadge };
