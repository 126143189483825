import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: 80vh;
  box-sizing: border-box;
  background-image: linear-gradient( 180deg, #004F9F 0%, #2C8AEA 100%);
`;

export { Container };
