import React from 'react';
import { Container } from './styles';

const Carrousel: React.FC = () => {
  return (
    <Container>
    </Container>
  );
};

export default Carrousel;
