
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: #fafafa;
  padding: 0;
  flex-direction: column;
  overflow-y: visible;
`;

export { Container };
