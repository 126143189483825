import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20%;
  box-sizing: border-box;
`;

export { Container };
