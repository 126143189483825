import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LandingPage from '../pages/LandingPage';
import RequiredAuth from './RequiredAuth';
import PrivacyPolicy from '../pages/PrivacyPolicy';

const RoutesWrapper: React.FC = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/politicas-de-privacidade" element={<PrivacyPolicy />} />
    <Route
      path="/home"
      element={<RequiredAuth>{/* <Home /> */}</RequiredAuth>}
    />
    <Route
      path="*"
      element={
        <div>
          <h1 className="text-center">Not Found 404</h1>
        </div>
      }
    />
  </Routes>
);

export default RoutesWrapper;
